"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
var reactivity_1 = require("@vue/reactivity");
exports["default"] = (0, vue_1.defineComponent)({
    props: {
        group: Object,
        users: Array,
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var filteredUsers = (0, reactivity_1.computed)(function () {
            return props.users.filter(function (user) {
                return !exclude_ids.value.includes(user.id);
            });
        });
        var areParticipantsPresent = (0, reactivity_1.computed)(function () {
            for (var _i = 0, _a = props.users; _i < _a.length; _i++) {
                var user = _a[_i];
                if (!exclude_ids.value.includes(user.id)) {
                    return true;
                }
            }
            return false;
        });
        var exclude_ids = (0, reactivity_1.computed)(function () {
            return [];
        });
        var csrf_token = (0, reactivity_1.computed)(function () {
            return window.csrf_token;
        });
        function searchInputKeyup() {
            $(".no_participant_1").addClass("d-none");
            // Retrieve the input field text and reset the count to zero
            var query = $(this).val(), count = 0;
            var isResultFound_1 = false;
            // Loop through the comment list
            $(".participant-modal-items-scollbar div a").each(function () {
                var $this = $(this);
                if ($(this).text().search(new RegExp(query, "i")) < 0) {
                    //isResultFound_1 = false;
                    $this
                        .parents("div")
                        .closest(".participant-modal-items")
                        .attr("style", "display: none !important");
                }
                else {
                    isResultFound_1 = true;
                    var isResultFound_div = 1;
                    $this
                        .parents("div")
                        .closest(".participant-modal-items")
                        .attr("style", "display: flex  !important");
                }
            });
            if (!isResultFound_1) {
                $(".no_participant_1").removeClass("d-none");
            }
            else {
                $(".no_participant_1").addClass("d-none");
            }
        }
        function addParticipantFormSubmit(e) {
            e.preventDefault();
            var participantIds = $('input[name="participant_ids[]"]:checked')
                .map(function () {
                return $(this).val();
            })
                .get();
            var formData = "groupId=" + props.group.id + "&physicianIds=" + participantIds;
            $.ajax({
                url: "/add-participant",
                type: "POST",
                data: formData,
                dataType: "json",
                beforeSend: function (xhr) {
                    $(".errorMsg").html("");
                    xhr.setRequestHeader("X-CSRF-Token", window.csrf_token);
                },
                success: function (response) {
                    if (response.success == false) {
                        $.each(response.errors, function (key, item) {
                            $("#field-" + key).html("<small>" + item[0] + "</small");
                        });
                    }
                    else {
                        $("#addParticipantsDetail").modal("hide");
                        $(".frm-addparticipant").trigger("reset");
                        $("#alert_box").modal("show");
                        $(".alert_message").html(response.message);
                        $(".alert_rediret_button").show();
                    }
                },
                error: function (response) { },
            });
        }
        function checkBoxChange(event) {
            var atLeastOneIsChecked = $('input[name="participant_ids[]"]:checked').length > 0;
            if (atLeastOneIsChecked) {
                $(".add_people").prop("disabled", false);
                $(".add_people").removeClass("disabled");
            }
            else {
                $(".add_people").prop("disabled", true);
                $(".add_people").addClass("disabled");
            }
            if (event.currentTarget.checked) {
                var yourArray = JSON.parse("[" + $(".participanthidden_ids").val() + "]");
                yourArray.push($(event.currentTarget).val());
                $(".participanthidden_ids").val(unique(yourArray));
            }
            else {
                var numbersString = $(".participanthidden_ids").val();
                if (!(typeof numbersString === "string")) {
                    throw new Error("Hidden IDs string is not a string.");
                }
                var currentCheckBoxValue = $(event.currentTarget).val();
                if (!(typeof currentCheckBoxValue === "string")) {
                    throw new Error("Current check box value is not a string.");
                }
                var yourArray = numbersString.split(",");
                var index = yourArray.indexOf(currentCheckBoxValue);
                if (index >= 0) {
                    yourArray.splice(index, 1);
                }
                $(".participanthidden_ids").val(unique(yourArray));
            }
        }
        function unique(array) {
            var unique_arr = [];
            array.forEach(function (i, e) {
                if (unique_arr.indexOf(i) === -1)
                    unique_arr.push(i);
            });
            return unique_arr;
        }
        var __returned__ = { props: props, filteredUsers: filteredUsers, areParticipantsPresent: areParticipantsPresent, exclude_ids: exclude_ids, csrf_token: csrf_token, searchInputKeyup: searchInputKeyup, addParticipantFormSubmit: addParticipantFormSubmit, checkBoxChange: checkBoxChange, unique: unique, computed: reactivity_1.computed };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
