/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import jquery from "jquery"
window.$ = window.jQuery = jquery

import "bootstrap"
import { ScrollSpy } from "bootstrap"

if ("/" === document.location.pathname) {
    jquery(() => {
        new ScrollSpy(document.getElementById("mainWindow"), {
            target: "#navigationItemsList",

            // The offset is rounded up, because the Bootstrap ScrollSpy component
            // appears to truncate fractional offsets in some cases (for example,
            // if the header's height and therefore the offset is 120.3 pixels,
            // the offset will be rounded to 120.0 pixels).
            // When the offset is lower than it should be, the zero-pixel position
            // (window scrolled all the way to the top) does not get included as
            // part of the first navigation item. This effect is very obvious to
            // users.
            // Rounding the offset up to the next pixel does not appear to have any
            // bad side effect.
            offset: Math.ceil(
                document
                    .getElementsByTagName("header")[0]
                    .getBoundingClientRect().height
            ),
        })
    })
}

import "blueimp-file-upload/js/jquery.fileupload"

window.Vue = window.VUE_DEVELOPMENT_MODE
    ? require("vue/dist/vue.esm-browser")
    : require("vue/dist/vue.esm-browser.prod")

function unique(array) {
    var unique_arr = []
    array.forEach(function (i, e) {
        if (unique_arr.indexOf(i) === -1) unique_arr.push(i)
    })
    return unique_arr
}

const pubnub = new PubNub({
    publishKey: window.publishKey,
    subscribeKey: window.subscribeKey,
    uuid: window.uuid,
})

async function submitUpdate(anUpdate, attachments = null) {
    if (attachments && attachments.files[0]) {
        await pubnub.sendFile({
            channel: activeChannel,
            file: attachments.files[0],
            message: {
                entry: activeUser.id,
                update: anUpdate,
            },
        })
    } else {
        if (!!anUpdate) {
            pubnub.publish({
                channel: activeChannel,
                message: {
                    entry: activeUser.id,
                    update: anUpdate,
                },
            })
        }
    }

    const updateText = document.getElementById("message")
    updateText.value = ""

    const attachmentsInput = document.getElementById("content_attachment")
    attachmentsInput.value = ""
}

/**
 *
 * @param {NodeList} section
 * @returns
 */
function checkSectionCompletion(section) {
    let isSectionStarted = false
    for (const field of section) {
        if (field.value) {
            isSectionStarted = true
            break
        }
    }

    let isSectionCompleated = true
    if (isSectionStarted) {
        for (const field of section) {
            if (!field.value) {
                isSectionCompleated = false
            }
        }
    }

    return isSectionCompleated
}

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import PubNub from "pubnub"
import { createApp, reactive, VueElement } from "vue"
import AddGroupParticipantForm from "./AddGroupParticipantForm.vue"
import GroupsDisplay from "./GroupsDisplay.vue"
import WorkAddressFrom from "./WorkAddressForm.vue"
import PostGraduateForm from "./PostGraduateForm.vue"
import ResetPasswordFrontForm from "./ResetPasswordFrontForm.vue"
import { eventNames } from "process"

/**
 * @type {unknown[]}
 */
let groups = reactive(window.groups)
let users = reactive(window.users)
const SUPPORTED_VIDEO_FILES = [".mp4", ".webm"]
const SUPPORTED_IMAGE_FILES = [".png", ".jpg", ".jpeg", ".gif"]
createApp({
    components: {
        "groups-display": GroupsDisplay,
        "work-address-form": WorkAddressFrom,
        "add-group-participant-form": AddGroupParticipantForm,
        "post-graduate-form": PostGraduateForm,
        "reset-password-front-form": ResetPasswordFrontForm,
    },
    data() {
        return {
            clientVersion: 1,
            groups: groups,
            users: users,
            showConversationsListOnSmScreen: !window.activeChannel,
            imageOnPreview: null,
            videoOnPreview: null,
            sectionMissedMessage: null,
            workAddressesAdded: 0,
            pgTrainingAdded: 0,
        }
    },
    methods: {
        /**
         *
         * @param {Event} event
         */
        removeElement(event) {
            /**
             * @type {HTMLDivElement}
             */
            const element = event.target.parentNode
            element.parentNode.removeChild(element)
        },

        addWorkaddress() {
            this.workAddressesAdded += 1
        },

        addElement() {
            this.pgTrainingAdded += 1
        },

        showConversationsList() {
            this.showConversationsListOnSmScreen = true
        },
        /**
         * @param {Event} event
         */

        addGroupFormSubmit(event) {
            event.preventDefault()

            var physicianIds = $('input[name="physician_ids[]"]:checked')
                .map(function () {
                    return $(event.currentTarget).val()
                })
                .get()

            let formData =
                $(event.currentTarget).serialize() +
                "&physicianIds=" +
                physicianIds
            $.ajax({
                url: addGroupEndpoint,
                type: "POST",
                data: formData,
                dataType: "json",
                beforeSend: function (xhr) {
                    $(".errorMsg").html("")
                },
                success: function (response) {
                    if (response.success == false) {
                        // console.log(response.errors);
                        $.each(response.errors, function (key, item) {
                            $("#field-" + key).html(
                                "<small>" + item[0] + "</small"
                            )
                        })
                    } else {
                        $("#newGroup").modal("hide")
                        $("#frm-addgroup").trigger("reset")
                        $("#alert_box").modal("show")
                        $(".alert_message").html(response.message)
                        $(".alert_rediret_button").show()
                    }
                },
                error: function (response) {},
            })
        },

        batchClassKeydown() {
            var kk = e.which
            if (kk > 95 && kk < 106) {
                return true
            } else if (kk > 47 && kk < 58) {
                return true
            } else if (kk == 8 || kk == 46) {
                return true
            } else {
                return false
            }
        },

        /**
         * @param {Event} event
         */
        checkProfileSections(event) {
            event.preventDefault()
            /**
             *@type HTMLFormElement
             */
            const updateProfileForm = event.target

            const basicInformationFields =
                updateProfileForm.querySelectorAll(".basic-information")
            const isBasicInformationCompleted = checkSectionCompletion(
                basicInformationFields
            )

            const workAddressFields = updateProfileForm.querySelectorAll(
                ".work-address-section"
            )
            const isWorkAddressSectionCompleted =
                checkSectionCompletion(workAddressFields)

            const medicalSchoolFields = updateProfileForm.querySelectorAll(
                ".medical-school-section"
            )
            const isMedicalSchoolSectionCompleted =
                checkSectionCompletion(medicalSchoolFields)

            if (!isBasicInformationCompleted) {
                this.sectionMissedMessage =
                    "Plase Complete the basic information section that you already started"
            } else if (!isWorkAddressSectionCompleted) {
                this.sectionMissedMessage =
                    "Plase Complete the Work Address section that you already started"
            } else if (!isMedicalSchoolSectionCompleted) {
                this.sectionMissedMessage =
                    "Plase Complete the Medical School section that you already started"
            } else {
                updateProfileForm.submit()
            }
        },

        /**
         * @param {Event} event
         */
        groupAddParticipantButtonClick(event) {
            const groupId = $(event.currentTarget).data("group-id")
            const url = `/group-addparticipants/${groupId}`

            $.ajax({
                url,
                type: "GET",
                dataType: "json",
                success(response) {
                    users.length = 0
                    for (const responseUser of response.associated_users) {
                        users.push(responseUser)
                    }
                    $("#addParticipantsDetail").modal("show")
                },
            })
        },

        /**
         * @param {Event} event
         */
        groupParticipantsButtonClick(event) {
            const dataUrl = $(event.currentTarget).data("url")
            if (dataUrl != "") {
                $.ajax({
                    url: dataUrl,
                    type: "GET",
                    dataType: "json",
                    success(response) {
                        $(".physician-modal-body").html(response.html)
                        $("#viewParticipantsDetail").modal("show")
                    },
                })
            }
        },

        /**
         * @param {Event} event
         */
        groupSearchKeyup(event) {
            console.log("groupSearchKeyup")
            const searchTxt = $(event.currentTarget).val()
            $.ajax({
                url: window.searchGroupEndpoint,
                type: "POST",
                data: {
                    _token: window.csrf_token,
                    searchTxt: searchTxt,
                },
                dataType: "json",
                success(response) {
                    groups.length = 0
                    for (const responseGroup of response.groups) {
                        groups.push(responseGroup)
                    }
                },
            })
        },

        /**
         * @param {Event} event
         */
        groupViewClick(event) {
            const dataUrl = $(event.currentTarget).data("url")
            if (dataUrl != "") {
                $.ajax({
                    url: dataUrl,
                    type: "GET",
                    dataType: "json",
                    success: function (response) {
                        $(".physician-modal-body").html(response.html)
                        $("#viewMoreDetail").modal("show")
                    },
                })
            }
        },

        individualAlertCloseButtonClick() {
            $("#individual_alert_box").modal("hide")
        },

        /**
         * @param {Event} event
         */
        individualJoinButtonClick(event) {
            const url = $(event.currentTarget).attr("data-url")
            const message = $(event.currentTarget).attr("data-message")
            $(".individual_join_message").html(message)
            $(".individualJoinConfirmButton").attr("data-url", url)
            $("#individual_join_box").modal("show")
        },

        individualJoinCloseButtonClick() {
            $("#individual_join_box").modal("hide")
        },

        /**
         * @param {Event} event
         */
        individualJoinConfirmButtonClick(event) {
            const dataUrl = $(event.currentTarget).attr("data-url")
            if (dataUrl != "") {
                $.ajax({
                    url: dataUrl,
                    type: "POST",
                    dataType: "json",
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader("X-CSRF-Token", window.csrf_token)
                    },
                    success: function (response) {
                        if (response.url) {
                            window.location.replace(response.url)
                        }
                    },
                    error: function (response) {},
                })
            }
        },

        /**
         * @param {Event} event
         */
        schoolChange(event) {
            const value = $(event.currentTarget).val()
            const element = document.getElementById("other_school_div")
            if (value == "other_school_option") {
                element.style.display = "block"
            } else {
                element.style.display = "none"
            }
        },

        /**
         * @param {Event} event
         */
        checkSpecialityChange(event) {
            const element_value = $(event.currentTarget).val()
            if (element_value) {
                if (element_value == "other_speciality_option") {
                    $(event.currentTarget)
                        .parents(".pg-traning-fields")
                        .find(".other_speciality_div")
                        .show()
                } else {
                    $(event.currentTarget)
                        .parents(".pg-traning-fields")
                        .find(".other_speciality_div")
                        .hide()
                }
            }
        },

        /**
         * @param {Event} event
         */
        deleteProfileButtonClick(event) {
            var id = $(event.currentTarget).data("id")
            var message = $(event.currentTarget).data("message")
            var modalmessage = $(event.currentTarget).data("modalmessage")
            $(".id").val(id)
            $(".status_button").text(message)
            $(".modalmessage").text(modalmessage)

            $("#confrom_status_modal").modal("show")
        },

        /**
         * @param {Event} event
         */
        editProfileStatusButtonClick(event) {
            const dataUrl = $(event.currentTarget).data("url")
            if (dataUrl != "") {
                $.ajax({
                    url: dataUrl,
                    type: "GET",
                    dataType: "json",
                    beforeSend(xhr) {
                        xhr.setRequestHeader("X-CSRF-Token", window.csrf_token)
                    },
                    success(response) {
                        window.location.href = response.url
                    },
                })
            }
        },

        /**
         * @param {Event} event
         */
        physicianCheckboxClick(event) {
            if (event.currentTarget.checked) {
                var yourArray = JSON.parse("[" + $("#hidden_ids").val() + "]")
                yourArray.push($(event.currentTarget).val())
                $("#hidden_ids").val(unique(yourArray))
            } else {
                var numbersString = $("#hidden_ids").val()
                var yourArray = numbersString.split(",")
                var index = yourArray.indexOf($(event.currentTarget).val())

                if (index >= 0) {
                    yourArray.splice(index, 1)
                }
                $("#hidden_ids").val(unique(yourArray))
            }
        },

        /**
         * @param {Event} event
         */
        postGraduateInstituteChange(event) {
            const element_value = $(event.currentTarget).val()
            if (element_value) {
                if (element_value == "other_pg_institute_option") {
                    $(event.currentTarget)
                        .parents(".pg-traning-fields")
                        .find(".other_pg_institute_div")
                        .show()
                } else {
                    $(event.currentTarget)
                        .parents(".pg-traning-fields")
                        .find(".other_pg_institute_div")
                        .hide()
                }
            }
        },

        /**
         * @param {Event} event
         */
        profileConnectButtonClick(event) {
            var dataUrl = $(event.currentTarget).data("url")
            if (dataUrl != "") {
                $.ajax({
                    url: dataUrl,
                    type: "POST",
                    dataType: "json",
                    beforeSend(xhr) {
                        xhr.setRequestHeader("X-CSRF-Token", window.csrf_token)
                    },
                    success(response) {
                        if (response.url) {
                            window.location.replace(response.url)
                        }
                    },
                    error(response) {},
                })
            }
        },

        /**
         * @param {Event} event
         */
        profileModalConnectButtonClick(event) {
            var dataUrl = $(event.currentTarget).data("url")
            var request_message = $(".request_message").val()
            if (
                request_message != "" &&
                request_message != null &&
                request_message.trim() !== ""
            ) {
                $(".error_request_message").text("")
                if (dataUrl != "") {
                    $.ajax({
                        url: dataUrl,
                        type: "POST",
                        dataType: "json",
                        data: {
                            _token: window.csrf_token,
                            request_message: request_message,
                        },
                        beforeSend(xhr) {
                            xhr.setRequestHeader(
                                "X-CSRF-Token",
                                window.csrf_token
                            )
                        },
                        success(response) {
                            if (response.url) {
                                window.location.replace(response.url)
                            } else {
                                $(".error_request_message").text(
                                    response.message
                                )
                            }
                        },
                        error(response) {},
                    })
                }
            } else {
                $(".error_request_message").text("Please enter request message")
            }
        },

        sendButtonClick() {
            console.log("sendButtonClick")
            const updateText = document.getElementById("message")
            const attachmentsInput =
                document.getElementById("content_attachment")
            submitUpdate(updateText.value, attachmentsInput)
            this.imageOnPreview = null
            this.videoOnPreview = null
        },

        sendNewInvitationButtonClick() {
            $.ajax({
                url: sendNewInvitationEndpoint,
                type: "POST",
                data: {
                    _token: window.csrf_token,
                    group_id: group_id,
                },
                dataType: "json",
                success: function (response) {
                    //alert(response.message);

                    $("#alert_box").modal("show")
                    $(".alert_message").html(response.message)
                    $(".alert_rediret_button").show()
                },
            })
        },

        /**
         * @param {Event} event
         */
        updateStatusButtonClick(event) {
            var user_id = $(event.currentTarget).data("user-id")
            var status = $(event.currentTarget).data("status")

            if (user_id != "" && status != "") {
                $.ajax({
                    url: statusMessagesUpdateEndpoint,
                    type: "POST",
                    data: {
                        _token: window.csrf_token,
                        group_id: window.groupId,
                        user_id: user_id,
                        status: status,
                    },
                    dataType: "json",
                    success: function (response) {
                        $("#viewParticipantsDetail").modal("hide")
                        location.reload(true)
                    },
                })
            }
        },

        /**
         * @param {KeyboardEvent} event
         */
        previewFile(event) {
            this.imageOnPreview = null
            this.videoOnPreviw = null
            const file = event.target.files[0]
            if (file.type.startsWith("image")) {
                this.imageOnPreview = URL.createObjectURL(file)
            } else if (file.type.startsWith("video")) {
                this.videoOnPreview = URL.createObjectURL(file)
            }
        },

        /**
         * @param {KeyboardEvent} event
         */
        messageInputKeydown(event) {
            const ENTER_KEY_CODE = 13
            if (event.keyCode == ENTER_KEY_CODE) {
                const updateText = document.getElementById("message")
                const attachmentsInput =
                    document.getElementById("content_attachment")
                submitUpdate(updateText.value, attachmentsInput)
                this.imageOnPreview = null
                this.videoOnPreview = null
            }
        },

        clearAttachment() {
            this.imageOnPreview = null
            this.videoOnPreview = null
            document.getElementById("content_attachment").value = ""
        },

        /**
         * @param {KeyboardEvent} event
         */
        postContactAjaxRequest(event) {
            event.preventDefault()
            $(".errorMsg").text("")
            $(".errorMsg").addClass("d-none")

            var form_obj = $(event.target)
            var form_data = form_obj.serializeArray()
            var submit_url = form_obj.data("action")

            $(".loader").addClass("d-flex")

            $.ajax({
                url: submit_url,
                type: "POST",
                data: form_data,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("X-CSRF-Token", csrf_token)
                },
                success: function (response) {
                    $(".loader").removeClass("d-flex")
                    console.log(response.responseJSON)
                    $(".modal-body").text(response.message)
                    $("#contactResponseModal").modal("show")
                },
                error: function (response) {
                    $(".loader").removeClass("d-flex")
                    const errors = response.responseJSON.errors
                    for (const [key, value] of Object.entries(errors)) {
                        console.log(key, value)
                        const errorLabel = $(".error-" + key)
                        errorLabel.text(value[0])
                        errorLabel.removeClass("d-none")
                    }
                },
            })
        },
    },
    created() {
        pubnub.subscribe({
            channels: groupChannels,
            withPresence: true,
        })
        pubnub.fetchMessages(
            {
                channels: groupChannels,
                stringifiedTimeToken: true,
                includeMeta: false,
                includeMessageActions: false,
            },
            function (status, response) {
                if (activeChannel && response) {
                    const messages = response.channels[activeChannel]
                    renderPastMessages(messages)
                }
            }
        )

        if (activeChannel) {
            console.log(activeChannel)
            const nowTimetoken = new Date().valueOf() * 10000
            const activeGroup = groups.find(
                (group) => group.pubnub_channel == activeChannel
            )
            if (activeGroup) {
                activeGroup.show_conversation = 1
            }
            setLastReadTimetoken(nowTimetoken)
        }

        getUnreadMessagesCount()

        pubnub.addListener({
            message: function (event) {
                if (event.channel == activeChannel) {
                    setLastReadTimetoken(event.timetoken)
                    displayMessage(event.message.entry, event.message.update)
                }

                updateConversationsOrder(event.channel)
            },
            presence: function (event) {
                console.log(
                    "presence: ",
                    `uuid:  ${event.uuid}, channel: ${event.channel}`
                )
            },
            status: function (event) {
                console.log(
                    "status: ",
                    `connected to channels: ${event.affectedChannels}`
                )
            },
            file: function (event) {
                if (event.channel == activeChannel) {
                    setLastReadTimetoken(event.timetoken)
                    displayMessage(
                        event.message.entry,
                        event.message.update,
                        event.file
                    )
                }
                updateConversationsOrder(event.channel)
            },
        })

        function deletePreviouseMessages(channel) {
            pubnub.deleteMessages(
                {
                    channel: channel,
                    start: "15526611838554310",
                    end: new Date().valueOf() * 10000,
                },
                function (status, response) {
                    console.log(status, response)
                }
            )
        }

        function setLastReadTimetoken(timetoken) {
            pubnub.objects.setMemberships({
                channels: [
                    {
                        id: activeChannel,
                        custom: {
                            lastReadTimetoken: timetoken,
                        },
                    },
                ],
            })
        }

        async function getUnreadMessagesCount() {
            const lastReadedMessages = await pubnub.objects.getMemberships({
                include: {
                    customFields: true,
                },
            })
            const lastReadedMessagesData = lastReadedMessages.data

            const channelsTimestamps = []
            const channelsVisited = []
            lastReadedMessagesData.forEach((lastMessageData) => {
                if (lastMessageData.channel.id != activeChannel) {
                    channelsTimestamps.push(
                        lastMessageData.custom.lastReadTimetoken
                    )
                    channelsVisited.push(lastMessageData.channel.id)
                }
            })

            pubnub.messageCounts(
                {
                    channels: channelsVisited,
                    channelTimetokens: channelsTimestamps,
                },
                (status, results) => {
                    if (results === undefined) {
                        console.warn(
                            "pubnub.messageCounts returned an undefined result."
                        )
                    } else if (results === null) {
                        console.warn(
                            "pubnub.messageCounts returned a null result."
                        )
                    } else {
                        setUnreadMessagesIndicators(results.channels)
                    }
                }
            )
        }

        function setUnreadMessagesIndicators(channels) {
            for (const channel in channels) {
                if (Object.hasOwnProperty.call(channels, channel)) {
                    if (channels[channel]) {
                        const conversation = document.querySelector(
                            `.unread-messages-indicator[data-channel="${channel}"]`
                        )
                        conversation.classList.add("active")
                        conversation.innerText = channels[channel]
                    }
                }
            }
        }

        function updateConversationsOrder(recievdOnChannel) {
            groups = groups.map((group) => {
                if (group.pubnub_channel == recievdOnChannel) {
                    group.show_conversation = 1
                }
                return group
            })
            console.log("Message received on: ", recievdOnChannel)
            const conversation = document.querySelector(
                `.group-list[data-channel="${recievdOnChannel}"]`
            )
            const conversationsList = document.getElementById(
                "groupPhysicianContainer"
            )
            conversationsList.removeChild(conversation)
            conversationsList.insertBefore(
                conversation,
                conversationsList.firstChild
            )

            if (recievdOnChannel != activeChannel) {
                const unreadIndicator = conversation.querySelector(
                    ".unread-messages-indicator"
                )
                unreadIndicator.classList.add("active")
                unreadIndicator.innerText = Number(unreadIndicator.innerText)
                    ? Number(unreadIndicator.innerText) + 1
                    : "1"
            }

            updateInDatabase(recievdOnChannel)
        }

        async function updateInDatabase(channel) {
            const endpoint = updateInDatabaseEndpoint
            const requestBody = new FormData()
            requestBody.append("_token", window.csrf_token)
            requestBody.append("channel", channel)

            const request = new Request(endpoint, {
                method: "POST",
                body: requestBody,
            })
            await fetch(request)
        }

        async function displayMessage(entry, aMessage, fileReceived = null) {
            const messageBubble = document.createElement("div")
            messageBubble.classList.add("messageBubble")
            const senderName = document.createElement("p")
            senderName.classList.add("senderName")
            const pmessage = document.createElement("p")
            pmessage.classList.add("pmessage")

            if (entry == activeUser.id) {
                messageBubble.classList.add("messageSended")
            } else {
                messageBubble.classList.add("messageRecieved")
            }
            senderName.appendChild(document.createTextNode(activeUser.name))
            pmessage.appendChild(document.createTextNode(aMessage))
            messageBubble.appendChild(senderName)
            if (fileReceived) {
                let fileElement
                const fileUrl = pubnub.getFileUrl({
                    channel: activeChannel,
                    id: fileReceived.id,
                    name: fileReceived.name,
                })
                SUPPORTED_IMAGE_FILES.forEach((fileType) => {
                    if (fileReceived.name.endsWith(fileType)) {
                        fileElement = document.createElement("img")
                    }
                })
                SUPPORTED_VIDEO_FILES.forEach((fileType) => {
                    if (fileReceived.name.endsWith(fileType)) {
                        fileElement = document.createElement("video")
                        fileElement.controls = true
                    }
                })
                fileElement.src = fileUrl
                messageBubble.appendChild(fileElement)
            }
            messageBubble.appendChild(pmessage)

            const messagesTop = document.getElementById("messageBody")
            messagesTop.appendChild(messageBubble)

            updateScroll()
        }

        async function renderPastMessages(messages) {
            if (messages) {
                messages.forEach(async (message) => {
                    if (message.message.file) {
                        const messageWithFile = message.message
                        const messageText = messageWithFile.message.update
                        const messageSender = messageWithFile.message.entry
                        const file = messageWithFile.file

                        displayMessage(messageSender, messageText, file)
                    } else {
                        const messageText = message.message.update
                        const messageSender = message.message.entry

                        displayMessage(messageSender, messageText)
                    }
                })
            }
        }

        function updateScroll() {
            const messagesTop = document.getElementById("messageBody")
            messagesTop.scrollTop = messagesTop.scrollHeight
        }
    },
}).mount("#vueApp")
