"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
var reactivity_1 = require("@vue/reactivity");
exports["default"] = (0, vue_1.defineComponent)({
    props: {
        groups: Array,
        activeGroup: Object,
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var groupListClick = function (event) {
            var url = $(event.currentTarget).data("url");
            console.log(url);
            window.location.replace(url);
        };
        var menuIconSrc = (0, reactivity_1.computed)(function () {
            return window.menuIconSrc;
        });
        var exitGroup = function (e) {
            var groupId = e.target.dataset.groupId;
            if (groupId) {
                $.ajax({
                    url: window.disconnectParticipants,
                    type: "POST",
                    data: {
                        _token: window.csrf_token,
                        group_id: groupId,
                        user_id: window.uuid,
                    },
                    dataType: "json",
                    success: function (response) {
                        if (props.activeGroup && props.activeGroup.id == groupId) {
                            window.location.replace("/group-chatroom");
                        }
                        else {
                            var conversation = props.groups.find(function (group) { return groupId == group.id; });
                            conversation.show_conversation = 0;
                        }
                    },
                });
            }
        };
        var removeConversation = function (e) {
            var groupId = e.target.dataset.groupId;
            if (groupId) {
                $.ajax({
                    url: window.hideConvesationEndpoint,
                    type: "POST",
                    data: {
                        _token: window.csrf_token,
                        group_id: groupId,
                        user_id: window.uuid,
                    },
                    dataType: "json",
                    success: function (response) {
                        if (props.activeGroup && props.activeGroup.id == groupId) {
                            window.location.replace("/group-chatroom");
                        }
                        else {
                            var conversation = props.groups.find(function (group) { return groupId == group.id; });
                            conversation.show_conversation = 0;
                        }
                    },
                });
            }
        };
        var __returned__ = { props: props, groupListClick: groupListClick, menuIconSrc: menuIconSrc, exitGroup: exitGroup, removeConversation: removeConversation, computed: reactivity_1.computed };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
